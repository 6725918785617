<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container
          :padded="!$vuetify.breakpoint.mobile"
          :paddedTopBottom="false"
        >
          <v-row no-gutters class="inverted">
            <v-col cols="12" xl="7" class="pl-10 pr-10 pb-10">
              <h2>Osteopathie</h2>
              <p>
                Osteo­pathie is een manuele genees­wijze die zich richt op de
                dyna­miek en onder­linge relaties van gewricht­en, organ­en en
                andere weefsels van het mense­lijk lich­aam. Hier­bij is het
                uitgangs­punt dat alles goed moet kunnen bewegen om gezond en
                zonder klacht­en te kunnen functio­neren. Functio­neert een deel
                van het lich­aam niet goed, dan beïn­vloedt dat ook de rest. Een
                osteo­paat benadert het lich­aam daarom als één geheel.
                Osteo­pathie is een veilige behandel­methode, gebaseerd op
                principes uit ana­tomie, fysio­logie, neuro­logie en
                embryo­logie.
              </p>
            </v-col>
            <v-col cols="12" xl="5">
              <div class="video-responsive">
                <iframe
                  src="https://www.youtube-nocookie.com/embed/rCDowQ28d4U?modestbranding=1&rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </v-col>
          </v-row>
        </Container>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <v-row>
            <v-col cols="12" md="6">
              <h3>Voor <em>baby's & kinderen</em></h3>

              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Indicatie klachten
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>Check-up na de geboorte</li>
                      <li>Schedelafwijkingen na een bevalling</li>
                      <li>Voorkeurshouding</li>
                      <li>Zuig- en slikproblemen</li>
                      <li>Huilbaby’s, onrustige kinderen</li>
                      <li>Darmkrampjes, obstipatie</li>
                      <li>Reflux</li>
                      <li>Buikpijn klachten zonder aanwijsbare oorzaak</li>
                      <li>
                        Gedrags- en leerproblemen als hyperactiviteit, dyslexie
                      </li>
                      <li>Problemen met motorisch ontwikkeling</li>
                      <li>Chronische oor- en bijholteontstekingen</li>
                      <li>Klachten aan het bewegingsapparaat</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <p>
                Door de kracht­en die tijdens de bevalling worden uit­geoefend
                op het lich­aam van een baby, kunnen er bewegings­beperking­en
                optreden ter hoogte van de schedel, nek en rug. Een
                osteopathische behandeling met zeer zachte tech­niek­en kan
                helpen om deze beperk­ingen te verhelp­en en hier­mee de
                ont­wikkeling van uw kind te bevor­deren.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <h3>Voor <em>Volwassenen</em></h3>

              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Indicatie klachten
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>
                        Pijn en/of stijfheid in rug, nek, schouder, heup of
                        andere ledematen
                      </li>
                      <li>Preventief</li>
                      <li>Bekkeninstabiliteit</li>
                      <li>Sportblessures</li>
                      <li>Posttraumatische klachten</li>
                      <li>Verschillende soorten hoofdpijn</li>
                      <li>Incontinenti problemen</li>
                      <li>Maag- en darmklachten</li>
                      <li>Klachten na (buik) operaties</li>
                      <li>Algemene buikklachten</li>
                      <li>Menstruatie stoornissen</li>
                      <li>Infertiliteit</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <p>
                Bij een gezond leven, hoort een gezond lichaam. Als vol­was­sene
                wordt uw lich­aam continu op de proef gesteld tijdens het
                dage­lijks leven. Als osteo­paat helpen wij u om uw lichaam in
                balans te breng­en en gezond te houden.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <h3>Voor <em>Zwangeren</em></h3>

              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Indicatie klachten
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>Rug-, been- en bekkenklachten</li>
                      <li>Pijn aan het stuitje</li>
                      <li>Tintelingen in armen en benen</li>
                      <li>Nek- en schouderklachten</li>
                      <li>Bekkenbodemklachten</li>
                      <li>Misselijkheid</li>
                      <li>Problemen met spijsvertering</li>
                      <li>Hoofdpijn</li>
                      <li>Zuuroprispingen</li>
                      <li>Steeds terugkerende blaasontsteking</li>
                      <li>Urineverlies</li>
                      <li>Harde buiken</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <p>
                Het lich­aam van de vrouw maakt veel veran­dering door tijdens
                de zwanger­schap. Door deze aan­passingen kunnen er
                klacht­en/onge­makken ontstaan. Osteo­pathie kan hierbij
                onder­steunend werken en tevens een positief effect hebben op
                het herstel van het lich­aam na de bevalling.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <h3>Voor <em>(top)sporters</em></h3>

              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Indicatie klachten
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>Overbelastingsblessures (zoals peesontstekingen)</li>
                      <li>Contactblessures (zoals verzwikkingen)</li>
                      <li>Overtraining</li>
                      <li>Prestatieverbetering</li>
                      <li>Preventie</li>
                      <li>Regelmatig terugkerende blessures</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <p>
                Als het lich­aam in balans is en optimaal kan bewegen, presteert
                u als sporter het beste. De kleinste bewegings­beperkingen
                kunnen ver­storend werken op de fijne coördi­natie tussen je
                gewricht­en en spieren. Hier­door neemt de kans op blessures toe
                of lukt het niet je normale niveau te halen. Osteo­pathie is een
                waarde­volle behandel­methode voor het verbeteren van de
                pres­taties, het voor­komen van blessures of het ver­helpen
                hiervan.
              </p>
            </v-col>
          </v-row>
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../components/Container.vue";

export default {
  name: "Osteopathie",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  em {
    font-style: normal;
    color: #747769;
  }
}

.inverted {
  background-color: #5d768e;
  h2 {
    color: #fff;
  }
  p {
    color: #fff;
  }
}

.video-responsive {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  height: 100%;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style-type: "▸ ";
    color: #000;
  }
}

.v-expansion-panels {
  margin-bottom: 1rem;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #cacfb8;
}
</style>
